import React from "react"
import { parse } from "qs"

import { SmallHeroLayout } from "../components/layouts"
import { ContactForm } from "../components/generic/contact-form"
import SEO from "../components/seo"

const ContactPage = () => {
  let enquiryType = "solo"
  if (typeof window !== "undefined") {
    const queryString = parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (typeof queryString.enquiryType === 'string') {
      enquiryType = queryString.enquiryType
    }
  }

  return (
    <SmallHeroLayout pageTitle="Contact us">
      <SEO title="Contact us" />
      <ContactForm enquiryType={enquiryType} />
    </SmallHeroLayout>
  )
}

export default ContactPage
